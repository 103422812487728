import {createApp} from 'vue'

import App from '@/App.vue'
import PrimeVue from 'primevue/config'
import router from '@/router'

//import i18n from '@/i18n'
import '@/assets/style/flags/flags.css'
import 'primeflex/primeflex.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/saga-blue/theme.css'

//MAZ-UI library
import 'maz-ui/css/main.css'

// global styles
import '@/assets/style/default.less'

import Button from 'primevue/button'
import Carousel from 'primevue/carousel'
import Image from 'primevue/image'
import SelectButton from 'primevue/selectbutton'
import Menubar from 'primevue/menubar'
import VueGtag from "vue-gtag"
import {createMetaManager, plugin as metaPlugin} from 'vue-meta'
import store from '@/store/index'

const app = createApp(App)
    .use(PrimeVue)
    //.use(i18n)
    .use(router)
    .use(store)
    /*
    .use(VueGtag, {
        config: {
            id: "GTM-MW4TX4N", // mrcall gtm
            //id: "G-2C4EZMZHVF", // mrcall website
            params: {
                anonymize_ip: true
            }
        },
        includes: [
            { id: 'GTM-MW4TX4N' } //MrCall gtm
        ]
    })
    */
    .use(metaPlugin)
    .use(createMetaManager(false, {
        meta: { tag: 'meta', nameless: true }
    }))

app.component('Button', Button)
app.component('Menubar', Menubar)
app.component('Carousel', Carousel)
app.component('Image', Image)
app.component('SelectButton', SelectButton)
router.isReady().then(() => app.mount('#app'))
