<template>
  <metainfo>
  </metainfo>
  <Navbar/>

  <div class="container h-full">
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'

export default {
  components: {
    Navbar
  },
  created() {
    let googleTagManager = document.head.querySelector(`[id="gtm"]`);
    if (!googleTagManager) {
      let tag = document.createElement("script");
      tag.setAttribute("id", 'gtm');
      tag.setAttribute("type", 'text/javascript');
      tag.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
          "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
          "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
          "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
          "})(window,document,'script','dataLayer','GTM-MW4TX4N');";
      document.head.prepend(tag);
    }

    /*
    let googleTagManagerNoScript = document.body.querySelector(`[id="gtmns"]`);
    if (!googleTagManagerNoScript) {
      let tag = document.createElement("noscript");
      tag.setAttribute("id", 'gtmns');
      let iframe = document.createElement("iframe");
      iframe.setAttribute("src", 'https://www.googletagmanager.com/ns.html?id=GTM-TZZ3QB6');
      iframe.setAttribute("height", '0');
      iframe.setAttribute("width", '0');
      iframe.setAttribute("style", 'display:none;visibility:hidden');
      tag.prepend(iframe);
      document.body.prepend(tag);
    }
    */
    //document.documentElement.html.setAttribute('lang', 'it')

    /*
    let iubenda1 = document.head.querySelector(`[id="iubenda1"]`);
    if (!iubenda1) {
      const tag = document.createElement("script");
      tag.setAttribute("id", 'iubenda1');
      tag.setAttribute("type", 'text/javascript');
      tag.innerHTML = 'var _iub = _iub || []; _iub.csConfiguration = {"ccpaAcknowledgeOnDisplay":true,"consentOnContinuedBrowsing":false,"countryDetection":true,"enableCcpa":true,"enableLgpd":true,"floatingPreferencesButtonDisplay":"bottom-right","invalidateConsentWithoutLog":true,"lgpdAppliesGlobally":false,"perPurposeConsent":true,"siteId":2787987,"whitelabel":false,"cookiePolicyId":13724557,"lang":"it", "banner":{ "acceptButtonCaptionColor":"#FFFFFF","acceptButtonColor":"#0073CE","acceptButtonDisplay":true,"backgroundColor":"#FFFFFF","brandBackgroundColor":"#FFFFFF","brandTextColor":"#000000","closeButtonDisplay":false,"customizeButtonCaptionColor":"#4D4D4D","customizeButtonColor":"#DADADA","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","listPurposes":true,"position":"float-bottom-center","rejectButtonCaptionColor":"#4D4D4D","rejectButtonColor":"#DADADA","rejectButtonDisplay":true,"textColor":"#000000","content":"Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per “interazioni e funzionalità semplici”, “miglioramento dell\'esperienza”, “misurazione”. Non per pubblicità né per vendita dei dati personali. Il rifiuto del consenso può rendere non disponibili le relative funzioni.\\\n\\\nPuoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento.\\\n\\\nUsa il pulsante “Accetta” per acconsentire all\'utilizzo di tali tecnologie. Usa il pulsante “Rifiuta” per continuare senza accettare." }};';
      document.head.appendChild(tag);
    }

    let iubenda2 = document.head.querySelector(`[id="iubenda2"]`);
    if (!iubenda2) {
      let tag = document.createElement("script");
      tag.setAttribute("id", 'iubenda2');
      tag.setAttribute("type", 'text/javascript');
      tag.setAttribute("src", 'https://cdn.iubenda.com/cs/ccpa/stub.js');
      document.head.appendChild(tag);
    }

    let iubenda3 = document.head.querySelector(`[id="iubenda3"]`);
    if (!iubenda3) {
      let tag = document.createElement("script");
      tag.setAttribute("id", 'iubenda3');
      tag.setAttribute("type", 'text/javascript');
      tag.setAttribute("src", 'https://cdn.iubenda.com/cs/iubenda_cs.js');
      tag.setAttribute("charSet", "UTF-8");
      tag.async = true;
      document.head.appendChild(tag);
    }
    */
  }
}
</script>

<style lang="less">
.mrcall-confirm-dialog .visibility-reject-button-off {
  visibility: hidden ;
}

.mrcall-confirm-dialog div.p-dialog-header-icons {
  visibility: hidden ;
}
</style>

<style lang="less" scoped>
</style>
