<template>
  <div id="navbar">
    <Menubar :model="itemsWeb">
      <template #start>
        <router-link to="/">
          <img class="mrCallLogo" src="@/assets/images/mrcall/logo_menu_mrcall.svg"/>
        </router-link>
      </template>
      <template #end>
      </template>
    </Menubar>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import router from "@/router";

export default {
  setup: function () {
    const store = useStore()
    const pageTexts = {
      it: {
        button: {
          functionalities: "Funzionalità",
          testimonials: "Dicono di noi",
          aboutUs: "Chi siamo",
          blog: "Blog",
          login: "Accedi",
          subscribe: "Iscriviti",
          assistants: "Assistente",
        }
      }
    }

    return {
      user: computed(() => store.state.user),
      userVerified: computed(() => store.state.userVerified),
      authIsReady: computed(() => store.state.authIsReady),
      itemsWeb: [
        {
          label: pageTexts.it.button.functionalities,
          command: () => {
            window.location.href = '/#features-part';
          }
        },
        {
          label: pageTexts.it.button.aboutUs,
          command: () => {
            router.push('/about_us');
          }
        },
        {
          label: pageTexts.it.button.testimonials,
          command: () => {
            window.location.href = '/#testimonials-part';
          }
        },
        {
          label: pageTexts.it.button.login,
          icon: 'pi pi-fw pi-sign-in',
          command: () => {
            window.location.href = process.env.VUE_APP_DASHBOARD_URL + '/login' ;
          }
        },
        {
          label: pageTexts.it.button.subscribe,
          icon: 'pi pi-fw pi-user-plus cta',
          command: () => {
            window.location.href = process.env.VUE_APP_DASHBOARD_URL + '/signup' ;
          }
        }
      ]
    }
  },
  methods: {
    gotoRoute: (route) => {
      router.push(route)
    },
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

@media screen and (max-width: 640px) {
  div.p-menubar.p-component {
    background-color: @mrcall_light_grey_1;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  div.p-toolbar.p-component {
    background-color: @mrcall_light_grey_1;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
@media screen and (min-width: 640px) {
  div.p-menubar.p-component {
    background-color: @mrcall_light_grey_1;
    opacity: 0.9;
    padding-left: 4rem;
    padding-right: 5rem;
  }

  div.p-toolbar.p-component {
    background-color: @mrcall_light_grey_1;
    opacity: 0.9;
    padding-left: 4rem;
    padding-right: 5rem;
  }
}

.mrCallLogo {
  width: 12rem;
  height: 4rem;
}

.p-menubar {
  position: relative;
  border: 0;
  padding: 0.5rem;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}

.p-toolbar {
  border: 0;
  padding: 0.5rem;
}

#navbar {
  background-color: @mrcall_light_grey_1;
  position: sticky;
  top:0;
  left:0;
  right:0;
  z-index: 100;
}
</style>